import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import SearchPatient from '../features/patients/SearchPatient';
import { useNavigate } from 'react-router-dom';

const TherapistPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const [newPatientData, setNewPatientData] = useState({ iin: '', fullname: '' });

    const navigate = useNavigate();

    

    const handlePatientFound = (patient) => {
    };

    return (
        <Box>
            <Button onClick={() => navigate('table-view')}>Табличный вид</Button>
            <SearchPatient
                role="therapist"
                onPatientFound={handlePatientFound}
            />

        </Box>
    );
};

export default TherapistPage;
