// Modules
import { Routes, Route } from "react-router-dom";
// Layouts
import Layout from "./components/Layouts/Layout";
import PersistLogin from "./components/Layouts/PersistLogin";
// Login
// import Register from "./features/auth/Register";
import Login from "./features/auth/Login";
import LoginRedirection from "./features/auth/LoginRedirection";
import RequireAuth from "./components/Layouts/RequireAuth";
// import LoginRedir from "./features/auth/LoginRedir";



import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Temp from "./pages/Temp";
import RegistratorPage from "./pages/RegistratorPage";
import AdminPage from "./pages/AdminPage";


import 'react-toastify/dist/ReactToastify.css';
import UploadPage from "./pages/UploadPage";
import TherapistPage from "./pages/TherapistPage";
import SpecialDoctorPage from "./pages/SpecialDoctorPage";
import TherapistTableViewPage from "./pages/TherapistTableViewPage";
import LoginRedir from "./features/auth/LoginRedir";



function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Routes>
          <Route path="/" element={<Layout />}>
            {/* change to redir later */}
            <Route index element={<LoginRedirection />} /> 
            <Route path="login" element={<Login />} />
            <Route path="login-redir" element={<LoginRedir />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route element={<PersistLogin />}>
              {/* <Route path="/redir" element={<LoginRedir />} /> */}
              <Route element={<RequireAuth allowedRoles={['registrator']}/>}>

                <Route path="registrator">
                  <Route index element={<RegistratorPage />} />
                  <Route path="add" element={<Login />} />
                </Route>
                
              </Route>
              <Route element={<RequireAuth allowedRoles={['ophthalmologist', 'otolaryngologist', 'surgeon', 'neurologist', 'gynecologist']}/>}>
                <Route path="doctor">
                  <Route index element={<Login />} />
                  <Route path="ophthalmologist" element={<SpecialDoctorPage role="ophthalmologist" />}/>
                  <Route path="otolaryngologist" element={<SpecialDoctorPage role="otolaryngologist" />}/>
                  <Route path="surgeon" element={<SpecialDoctorPage role="surgeon" />}/>
                  <Route path="neurologist" element={<SpecialDoctorPage role="neurologist" />}/>
                  <Route path="gynecologist" element={<SpecialDoctorPage role="gynecologist" />}/>
                </Route>
              </Route>

              <Route element={<RequireAuth allowedRoles={['therapist']}/>}>
                <Route path="therapist">
                  <Route index element={<TherapistPage />} />
                  <Route path="table-view" element={<TherapistTableViewPage />} />
                </Route>
              </Route>

              <Route element={<RequireAuth allowedRoles={['admin']}/>}>
                <Route path="admin">
                  {/* list, upload, statistics? */}
                  <Route index element={<AdminPage />} /> 
                  <Route path="upload" element={<UploadPage />} />
                  <Route path="stats" element={<Login />} />
                </Route>
              </Route>
              
            </Route>
            <Route path="*" element={<Missing />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
