import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import SearchPatient from '../features/patients/SearchPatient';

const RegistratorPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const [showAddPatientModal, setShowAddPatientModal] = useState(false);
    const [newPatientData, setNewPatientData] = useState({
        iin: '',
        fullname: '',
        company_enroller: '',
        dob: '',
        gender: '',
        position: ''
    });
    const [errors, setErrors] = useState({});

    // Fetch companies data using React Query v5
    const fetchCompanies = async () => {
        const response = await axiosPrivate.get('/api/contract/companies');
        return response.data;
    };

    const { data: companies, isLoading, isError } = useQuery({
        queryKey: ['companies'],
        queryFn: fetchCompanies,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    const validateForm = () => {
        const newErrors = {};
        if (!newPatientData.iin || newPatientData.iin.length !== 12 || !/^\d{12}$/.test(newPatientData.iin)) {
            newErrors.iin = 'ИИН должен содержать 12 цифр';
        }
        if (!newPatientData.fullname || !/^[a-zA-Zа-яА-Я\s]+$/.test(newPatientData.fullname)) {
            newErrors.fullname = 'Укажите ФИО корректно';
        }
        if (!newPatientData.position || !/^[a-zA-Zа-яА-Я\s]+$/.test(newPatientData.position)) {
            newErrors.position = 'Должность не должна содержать цифр или других знаков';
        }
        if (!newPatientData.company_enroller) {
            newErrors.company_enroller = 'Укажите компанию заказчика.';
        }
        if (!newPatientData.dob) {
            newErrors.dob = 'Укажите дату рождения пациента';
        }
        if (!newPatientData.gender) {
            newErrors.gender = 'Укажите пол пациента.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAddPatient = async () => {
        if (!validateForm()) {
            toast.error('Please fill in all required fields correctly.');
            return;
        }

        try {
            const data = {
                company_enroller: newPatientData.company_enroller,
                patient: {
                    iin: newPatientData.iin,
                    fullname: newPatientData.fullname,
                    dob: newPatientData.dob,
                    gender: newPatientData.gender,
                    position: newPatientData.position
                }
            };

            await axiosPrivate.put('/api/contract/add-patient', data);
            toast.success('Пациент успешно добавлен');
            setShowAddPatientModal(false);
            setNewPatientData({ iin: '', fullname: '', company_enroller: '', dob: '', gender: '', position: '' });
            setErrors({});
        } catch (error) {
            console.log(error);
            if(error.response.status === 409) {
                toast.dark('Пациент был найден в системе, отмечен Регистратором');
                setShowAddPatientModal(false);
                setNewPatientData({ iin: '', fullname: '', company_enroller: '', dob: '', gender: '', position: '' });
                setErrors({});
            }
            else toast.error('Ошибка при добавлении пациента');
        }
    };

    const handlePatientFound = (patient) => {
        if (!patient) {
            setShowAddPatientModal(true);
        }
    };

    if (isLoading) return <CircularProgress />;
    if (isError) return <div>Error loading companies</div>;

    return (
        <Box>
            <SearchPatient
                role="registrator"
                onPatientFound={handlePatientFound}
            />

            <Dialog open={showAddPatientModal} onClose={() => setShowAddPatientModal(false)}>
                <DialogTitle>Добавить пациента</DialogTitle>
                <DialogContent>
                    <DialogContentText>Введите данные нового пациента.</DialogContentText>
                    <FormControl fullWidth margin="dense">
                        <InputLabel error={!!errors.company_enroller}>Компания Заказчик</InputLabel>
                        <Select
                            label="Компания Заказчик"
                            value={newPatientData.company_enroller}
                            onChange={(e) => setNewPatientData({ ...newPatientData, company_enroller: e.target.value })}
                            error={!!errors.company_enroller}
                        >
                            {companies.map(company => (
                                <MenuItem key={company} value={company}>{company}</MenuItem>
                            ))}
                        </Select>
                        {errors.company_enroller && <Typography color="error">{errors.company_enroller}</Typography>}
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="ИИН"
                        type="text"
                        fullWidth
                        value={newPatientData.iin}
                        onChange={(e) => setNewPatientData({ ...newPatientData, iin: e.target.value })}
                        error={!!errors.iin}
                        helperText={errors.iin}
                    />
                    <TextField
                        margin="dense"
                        label="ФИО"
                        type="text"
                        fullWidth
                        value={newPatientData.fullname}
                        onChange={(e) => setNewPatientData({ ...newPatientData, fullname: e.target.value })}
                        error={!!errors.fullname}
                        helperText={errors.fullname}
                    />
                    <TextField
                        margin="dense"
                        label="Дата рождения"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={newPatientData.dob}
                        onChange={(e) => setNewPatientData({ ...newPatientData, dob: e.target.value })}
                        error={!!errors.dob}
                        helperText={errors.dob}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel error={!!errors.gender}>Пол</InputLabel>
                        <Select
                            label="Пол"
                            value={newPatientData.gender}
                            onChange={(e) => setNewPatientData({ ...newPatientData, gender: e.target.value })}
                            error={!!errors.gender}
                        >
                            <MenuItem value="Мужской">Мужской</MenuItem>
                            <MenuItem value="Женский">Женский</MenuItem>
                        </Select>
                        {errors.gender && <Typography color="error">{errors.gender}</Typography>}
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Должность"
                        type="text"
                        fullWidth
                        value={newPatientData.position}
                        onChange={(e) => setNewPatientData({ ...newPatientData, position: e.target.value })}
                        error={!!errors.position}
                        helperText={errors.position}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowAddPatientModal(false)}>Отмена</Button>
                    <Button onClick={handleAddPatient} variant="contained" color="success">Добавить</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default RegistratorPage;
