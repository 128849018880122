import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import SearchPatient from '../features/patients/SearchPatient';

const SpecialDoctorPage = ({role}) => {
    const axiosPrivate = useAxiosPrivate();
    const [newPatientData, setNewPatientData] = useState({ iin: '', fullname: '' });

    

    const handlePatientFound = (patient) => {
    };

    return (
        <Box>
            <SearchPatient
                role={role}
                onPatientFound={handlePatientFound}
            />

        </Box>
    );
};

export default SpecialDoctorPage;
