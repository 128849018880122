import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import PatientTable from '../features/patients/PatientTable';
import PatientDetails from '../features/patients/PatientDetails';


const TherapistTableViewPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const [selectedPatient, setSelectedPatient] = useState(null);
    const navigate = useNavigate();


    // Function to fetch contracts data
    const fetchContracts = async () => {
        const response = await axiosPrivate.get('/api/contract');
        return response.data;
    };

    // Fetch contracts data using React Query
    const { data: contracts, isLoading, isError, refetch } = useQuery({
        queryKey: ['contracts'],
        queryFn: fetchContracts,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    // Combine all patients from different contracts
    const patients = contracts ? contracts.flatMap(contract => 
        contract.patients.map(patient => ({
            ...patient,
            company_enroller: contract.company_enroller,
            contract_id: contract._id,
            date_of_contract: contract.date_of_contract
        }))
    ) : [];

    const handleRead = (patient) => {
        setSelectedPatient(patient);
    };

    const handleDelete = async (patient) => {
        await axiosPrivate.put(`/api/contract/delete-patient/${patient.contract_id}`, {
            iin: patient.iin
        });
        refetch(); // Refetch contracts data after delete
        if (selectedPatient && selectedPatient.iin === patient.iin) {
            setSelectedPatient(null);
        }
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" sx={{ marginBottom: 3 }}>Панель Управления</Typography>
            {isLoading ? (
                <Typography>Загрузка...</Typography>
            ) : isError ? (
                <Typography color="error">Ошибка при загрузке данных</Typography>
            ) : (
                <PatientTable role="therapist" patients={patients} onRead={handleRead} onDelete={handleDelete} />
            )}
            {selectedPatient && <PatientDetails patient={selectedPatient} />}
        </Box>
    );
};

export default TherapistTableViewPage;
