import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { jwtDecode } from "jwt-decode";

const LoginRedir = () => {
    const { auth } = useAuth();
    
    const decoded = auth?.accessToken
        ? jwtDecode(auth.accessToken)
        : undefined;
    
    const role = decoded?.role || [];

    switch(role) {
        case 'admin': return <Navigate to="/admin" replace />
        case 'therapist': return <Navigate to="/therapist/table-view" replace />
        case 'registrator': return <Navigate to="/registrator" replace />
        case 'ophthalmologist': return <Navigate to="/doctor/ophthalmologist" replace />
        case 'otolaryngologist': return <Navigate to="/doctor/otolaryngologist" replace />
        case 'surgeon': return <Navigate to="/doctor/surgeon" replace />
        case 'neurologist': return <Navigate to="/doctor/neurologist" replace />
        case 'gynecologist': return <Navigate to="/doctor/gynecologist" replace />
        default: break;
    }

    return <Navigate to="/login" replace />
}

export default LoginRedir
